import BaseService from './BaseService'
import { protectedRequest } from '@/boot/axios'

class ClientAdvertiserService extends BaseService {
  constructor() {
    super('client-advertisers')
  }

  addServices({ clients, services, projectId }) {
    return protectedRequest({
      method: 'POST',
      url: `${this._apiUrl}/add-services`,
      data: {
        clients,
        services,
        projectId,
      },
    })
  }
}

export default new ClientAdvertiserService()
